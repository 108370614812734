import { PageIndexContext } from "App";
import { Section } from "components";
import { infoSubmenus } from "components/Layout/Header";
import { useContext, useEffect } from "react";
import "sass/InfoChurch.scss";

export function InfoChurchContainer() {
  const { setCurrentPageIndex } = useContext(PageIndexContext);

  useEffect(() => {
    setCurrentPageIndex(1);
  });
  return (
    <Section
      direction="Right"
      title="소개"
      submenus={infoSubmenus}
      subtitle="교회소개"
    >
      <div className="church-container">
        <div className="church-section">
          <p className="church-intro-desktop">
            낙원교회는<br></br>1973년 설립되었으며<br></br>성경을 하나님의
            말씀으로 믿는
            <br></br>
            개혁주의 성경관의<br></br>대한예수교 장로회 합동측 교회입니다
          </p>
          <p className="church-intro-mobile">
            낙원교회는 1973년
            <br />
            설립되었으며 성경을 하나님의
            <br />
            말씀으로 믿는 개혁주의
            <br />
            성경관의 대한예수교 장로회
            <br />
            합동측 교회입니다
          </p>
          <img
            alt=""
            className="church-intro-image"
            src="/img/img_church.jpeg"
          />
        </div>
        <div className="section-divider"></div>
        <div className="church-section vertical">
          <p className="intro-people-title">섬기는 이들</p>
          {/* <div className="intro-people-senior">
            <img src="/img/people/img_people1.png" alt=""></img>
            <div className="senior-text-container">
              <div>인사말</div>
              <div className="senior-name">담임목사 신헌재</div>
            </div>
          </div> */}
          <div className="intro-people-assistant">
            <div className="assistant-body">
              <img src="/img/people/img_people1.png" alt=""></img>
              <div className="assistant-name">담임목사 신헌재</div>
            </div>
            <div className="assistant-body">
              <img src="/img/people/img_people3.png" alt=""></img>
              <div className="assistant-name">부목사 조병민</div>
              <div className="assistant-part">중고등부 및 교구 담당</div>
            </div>
            <div className="assistant-body">
              <img src="/img/people/img_people4.png" alt=""></img>
              <div className="assistant-name">교육전도사 강형민</div>
              <div className="assistant-part">청년부 담당</div>
            </div>
            {/* <div className="assistant-body">
              <img src="/img/people/img_people2.png" alt=""></img>
              <div className="assistant-name">교육전도사 문성길</div>
              <div className="assistant-part">유초등부 담당</div>
            </div> */}
          </div>
        </div>
        <div className="section-divider"></div>
        <div className="church-section vertical">
          <p className="intro-people-title">주일학교</p>
          <div className="church-school-grid">
            <div className="school-grid-item">
              <img src="/img/img_church.jpeg" alt="" />
              <p>유치부</p>
            </div>
            <div className="school-grid-item">
              <img src="/img/department/img_elementary.jpeg" alt="" />
              <p>유초등부</p>
            </div>
            <div className="school-grid-item">
              <img src="/img/department/img_youth.jpeg" alt="" />
              <p>엔 크리스토 중고등부</p>
            </div>
            <div className="school-grid-item">
              <img src="/img/img_church.jpeg" alt="" />
              <p>낙랑 청년부</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
