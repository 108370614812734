import { PageIndexContext } from "App";
import MetaTag from "SEOMetaTag";
import { MainBanner } from "components";
import MenuButton from "components/common/MenuButton";
import { useContext, useEffect } from "react";
import "sass/HomeContainer.scss";

export function HomeContainer() {
  const { setCurrentPageIndex } = useContext(PageIndexContext);

  useEffect(() => {
    setCurrentPageIndex(0);
  });

  return (
    <>
      <MetaTag
        title="낙원교회"
        description="서울시 강북구 미아동에 위치한 대한예수교 장로회 합동측 낙원교회입니다."
        keywords="강북구, 미아동, 삼양동, 낙원교회"
        url=""
      ></MetaTag>
      <div className="home-container">
        <MainBanner></MainBanner>
        {/* <div className="main-title">
          여호와는 나의 목자시니 내게 부족함이 없으리로다
          <br />
          시편 23편 1절
        </div> */}
        <div className="main-menu-container">
          <div className="main-menu-body">
            <MenuButton
              title="교회소식"
              img="/img/ic_notice.png"
              url="notice"
              colorHex="#EEEBA0"
            />
            <MenuButton
              title="주보"
              img="/img/ic_bulletin.png"
              url="bulletin"
              colorHex="#95E6EB"
            />
            <MenuButton
              title="예배안내"
              img="/img/ic_church.png"
              url="info/worship"
              colorHex="#7CD77B"
            />
            <MenuButton
              title="오시는 길"
              img="/img/ic_map.png"
              url="info/location"
              colorHex="#E7ACAC"
            />
          </div>
        </div>
      </div>
    </>
  );
}
